<template>
  <v-container
    fluid
    tag="section"
  >
    <v-tabs
      v-if="id.some(i => $store.getters.isStbMWkDiscipline(i))"
      grow
    >
      <v-tab href="#tab-kombiniert">kombinierte Dateneingabe</v-tab>
      <v-tab-item value="tab-kombiniert"><kombiniert :ids="id" :df="DisciplineFind" /></v-tab-item>
    </v-tabs>
    <v-tabs
      v-else
      v-model="tab"
      grow
      id="inputform"
    >
      <template
        v-for="e in events"
      >
        <v-tab
          v-if="isWkDiscipline(e._id) || e.teams.some(t => isTeam(t.team._id))"
          :key="e._id"
          style="font-size:80%;"
        >
          {{ e.name }}<br>{{ e.parent.name }}<br>{{ e.teams.length === 2 ? e.teams.map(t => t.team.name).join(', ') : undefined }}
        </v-tab>
      </template>
      <v-btn
        text
        fab
        small
        @click="fullscreen"
      >
        <v-icon v-if="!isFullscreen">
          far fa-expand-wide
        </v-icon>
        <v-icon v-else>
          far fa-compress-wide
        </v-icon>
      </v-btn>

      <template
        v-for="e in events"
      >
        <v-tab-item
          v-if="isWkDiscipline(e._id) || e.teams.some(t => isTeam(t.team._id))"
          :key="e._id"
        >
          <index :id="e._id" :df="DisciplineFind" :f="isFullscreen" />
        </v-tab-item>
      </template>
    </v-tabs>
  </v-container>
</template>

<script>
import Vue from 'vue'
import gql from 'graphql-tag'
import { deleteQuery, updateQuery } from '@/plugins/graphql'
import { useAuth } from '@/plugins/auth'

export default {
  name: 'liveinput',

  components: {
    Index: () => import('./input/index'),
    Kombiniert: () => import('./input/kombiniert')
  },

  setup (props, context) {
    return {
      ...useAuth(context)
    }
  },

  props: {
    ids: {
      type: String,
      required: true
    }
  },

  data: () => ({
    Events: {},
    tab: 0,
    isFullscreen: false,
    DisciplineFind: []
  }),

  computed: {
    id () {
      return this.ids.split(',')
    },
    lf () {
      return this.events.every(e => e.mode.match(/^l[pf]_/))
    },
    events () {
      return Object.values(this.Events).filter(e => !!this.id.find(id => e._id === id))
    }
  },

  methods: {
    fullscreen () {
      this.$fullscreen.toggle(this.$el.querySelector('#inputform'), {
        wrap: false,
        callback: this.fullscreenChange
      })
    },
    fullscreenChange (fullscreen) {
      this.isFullscreen = fullscreen
    },
    fetch (id) {
      this.$apollo.query({
        query: gql`query($id: UUID!) {
          Event(id: $id) {
            _id
            type
            ...on StbM2021Wk {
              name mode
              parent {
                ... on StbM2021League { name shortName }
              }
              teams {
                team { _id name }
              }
            }
          }
        }`,
        variables: {
          id
        }
      }).then(({ data }) => {
        Vue.set(this.Events, id, data.Event)
      })
    },
    fetchData () {
      this.id.forEach(id => {
        this.fetch(id)
      })
    }
  },

  created () {
    this.fetchData()
  },

  watch: {
    id () {
      this.fetchData()
    }
  },

  apollo: {
    DisciplineFind: {
      query: gql`query {
        DisciplineFind { _id name shortName regular }
      }`,
      fetchPolicy: 'cache-first',
      subscribeToMore: {
        document: gql`subscription {
          DisciplineCreate { _id name shortName regular }
        }`,
        updateQuery: updateQuery('DisciplineFind', 'DisciplineCreate')
      }
    },
    $subscribe: {
      DisciplineUpdate: {
        query: gql`subscription {
          DisciplineUpdate { _id name shortName regular }
        }`
      },
      DiscliplineDelete: {
        query: gql`subscription {
          DisciplineDelete
        }`,
        result (id) {
          deleteQuery('DisciplineFind', 'DisciplineDelete', this.DisciplineFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
